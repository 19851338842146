<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Image Categories</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters> </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-for="category in categories" v-bind:key="category.id">
          <v-card>
            <v-img
              v-if="category.default_image_id"
              :src="category.default_image.asset_urls.thumbnail"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
            >
              <v-card-title>{{ category.name }}</v-card-title>
              <v-card-subtitle class="white--text">{{
                category.default_image.name
              }}</v-card-subtitle>
            </v-img>
            <v-img
              v-else
              src="@/assets/placeholder.svg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
            >
              <v-card-title>{{ category.name }}</v-card-title>
              <v-card-subtitle class="white--text"
                >Please choose</v-card-subtitle
              >
            </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                color="blue"
                @click="
                  $refs.imageSelector.openDialog(
                    category,
                    category.default_image_id,
                    true
                  )
                "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Image</v-card-title>
        <v-card-text>Are you sure you want to delete this image?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ImageSelector ref="imageSelector" />
  </div>
</template>

<script>
import ImageSelector from "../components/ImageSelector";

export default {
  components: {
    ImageSelector,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Admin",
          disabled: true,
        },
        {
          text: "Images",
          disabled: false,
          exact: true,
          to: { name: "module-drum-admin-images" },
        },
        {
          text: "Categories",
          disabled: true,
        },
      ],
      deleteDialog: {
        open: false,
        loading: false,
        image: {},
      },
    };
  },

  computed: {
    images() {
      let images = this.$store.state.drum.admin["images"];

      if (this.searchTerm !== "") {
        images = images.filter((i) => {
          const name = i.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return images;
    },
    categories() {
      return this.$store.state.drum.admin["imageCategories"];
    },
  },

  methods: {
    openDelete(image) {
      this.deleteDialog.image = image;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.open = false;
      this.deleteDialog.loading = false;
      this.deleteDialog.image = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("drum/admin/deleteImage", {
          appId,
          imageId: this.deleteDialog.image.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>